import { Component } from '@angular/core';
import { KioskauthService } from '../services/kioskauth.service';
import { LocalStoreService } from '../services/localstore.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateConfigService } from '../services/translate-config.service';
import { UtilityService } from '../services/utility.service';
import { ApiService } from '../services/api.service';
import { modalTimeout, page_redirection_time, setIntervalTimeOut, telemetryTimeout, cart_time, KIOSK_AUTO_LOGIN, terminalCheck, terminal_check_enable, menu_items_timer, images_display_timer } from '../common/api';
import { TranslateService } from '@ngx-translate/core';
import { CartItemDetailPage } from '../cart-item-detail/cart-item-detail.page';
import { PageLoaderService } from '../services/page-loader.service';
import { environment } from 'src/environments/environment';
import { AuditLogService } from '../services/audit-log.service';
import { KioskApiService } from '../services/kiosk-api.service';
import { ConditionalExpr } from '@angular/compiler';



@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {
  terminal_modal
  params: any;
  maintain_timer: any;
  is_maintaince_mode_on = false;
  appSettings: any;
  shop: any;
  type = "0"
  menuItems = {};
  totalAmount: number;
  settings = {};
  orderType = "";
  currency = "¥";
  showDecimal = true;
  cartItems: [];
  cartItemCount: number;
  totalCartAmount: number = 0;
  payment: any;
  timer_start = true;
  menu_item: boolean;
  page_redirection_time_left = page_redirection_time;
  time_left_redirection_counter: any;
  is_restaurant_closed_status = "NO";
  time_left_counter: any;
  time_left = 0;
  time_stop = false;
  show_cancel_button = false;
  mid_cancel = false;
  obj_restaurantstatus;
  store_close_status;
  restaurant_mode;
  cartTotalItems: any = [];
  obj_menuItems;
  obj_masterrestaurant
  obj_restaurantoperational
  obj_storeinfo
  restaurant_status_interval: any;
  obj_restaurantDetails;
  app_maintanance_description2;
  app_ella_maintanance_description2;
  app_store_closed_description2;
  terminalResponse: any;
  hide_cancel_button: any;
  payprog_lock: any;
  subtype: any;
  deducted: any;
  attempted: any;
  obj_AppSetting;
  app_settings;
  cartItems_create_order: any = [];
  ServiceID: any;
  isPaymentFailed;
  balance: any;
  createorderRes;
  order_UUID;
  proc_card_idi;
  terminalId;
  cartItemDetail;
  cartItemDetailslength;
  _cartItemDetail: any = [];
  haveMaxEllaCoffee: boolean;
  coffeeCount: any;
  ella_max_drinks;
  telemetry_poller;
  store_info: any;
  headerShow:boolean;
  home_banner_image:any;
  display_images:any;
  menuItems_timer:any;
  menuItems_length;

  constructor(
    private router: Router,
    private kioskauthservice: KioskauthService,
    private route: ActivatedRoute,
    private modalController: ModalController,
    private store: LocalStoreService,
    private translateConfigService: TranslateConfigService,
    private utilityService: UtilityService,
    private api: ApiService,
    public alertController: AlertController,
    private translate: TranslateService,
    private pageLoaderService: PageLoaderService,
    public auditlogServices: AuditLogService,
    private kiosk_apiService: KioskApiService,
    private auditlogservice: AuditLogService,
  ) {
    this.translateConfigService.getDefaultLanguage();
    this.showDecimal = true;
    if (this.store.getCountry()) {
      let countryData = this.store.getCountry();
      this.currency = countryData['master_country_currency_code'];
      if (countryData['master_country_currency_decimal_places'] == 0) {
        this.showDecimal = false;
      }
    }
  }

  ngOnInit() {
    this.is_restaurant_closed();
    this.getRestaurantDetails();
    this.menu_item = false;
    this.time_stop = false;
    this.time_left = cart_time;
    clearInterval(this.time_left_counter);
    clearInterval(this.maintain_timer);
    this.getAppSettings();
    this.payment_printing_setting();
    this.terminalId = this.store.get('terminalid')
  }

  is_restaurant_closed() {
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
    this.obj_restaurantstatus = this.kiosk_apiService.restaurant_close_status(master_restaurant_uuid)
      .subscribe(
        (res) => {
          if (res.statusCode == 200) {
            if (res.aaData == true) {
              this.is_restaurant_closed_status = "NO";
            } else {
              this.is_restaurant_closed_status = "YES";
            }
          }
          if (res.statusCode != 200) {
            if (res.aaData == false) {
              this.technical_error()
            }
          }
          this.homePageScreen()
        },
        error => {
          this.technical_error()
        }
      );
  }

  ionViewWillEnter() {
    this.homePageScreen()
    this.terminal_modal = this.store.get('terminal_modal')
    setInterval( () =>{
      this.terminal_modal = this.store.get('terminal_modal')
    },500)
    this.kiosk_apiService.telemetry();
    this.telemetry_poller = setInterval(
      ()=>{
       this.kiosk_apiService.telemetry()
      }
    , terminalCheck);

    this.getMenuItems();
    this.reset_time();
    this.store_close_status = setInterval(
      () => {
        this.is_restaurant_closed();
      }
      , 10000);
    this.restaurant_mode = setInterval(
      () => {
        this.getRestaurantDetails();
      }
      , 12000);
      // this.display_images =setInterval(()=>{
      //   this.getAppSettings();
      // },images_display_timer)
      this.menuItems_timer =setInterval(()=>{
        this.getMenuItems();
      },menu_items_timer)

    this.clock_Timer();
    this.orderType = this.store.get('order_type');
    this.getCartItems();
    if (this.cartItemCount == 0) {
      clearInterval(this.time_left_counter);
    }
    this.audit_log()
    this.cartEllaItems()
  }

  getMenuItems() {
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid");
    let user_information_uuid = localStorage.getItem("user_information_uuid");
    let payload = {
      "ref": "kiosk",
      "master_restaurant_uuid": master_restaurant_uuid,
      "user_information_uuid": user_information_uuid
    };
    this.obj_menuItems = this.kiosk_apiService.getMenuItems().subscribe((response) => {
      if (response.status === false) {
        this.kioskauthservice.onError(response.message);
      } else {
        if (response.final_response) {
          this.menu_item = true;
          this.menuItems = response.final_response;
          this.menuItems_length = Object.keys(this.menuItems).length
        } else {
          this.menu_item = true;
          this.menuItems = response.aaData;
          this.menuItems_length = Object.keys(this.menuItems).length
        }
      }
    },
      error => {
        this.technical_error()
      }
    );
  }

  async redirectOnDetailPage(item) {
    let restaurantDetails = this.store.getRestaurant();
    let master_restaurant_uuid = restaurantDetails.master_restaurant_uuid;
    this.obj_masterrestaurant = this.kiosk_apiService.restaurant_close_status(master_restaurant_uuid)
      .subscribe(async (res) => {
        if (res.statusCode == 200) {
          if (res.aaData == true) {
            this.redirectOnItemDetailPage(item);
          } else {
            return false;
          }
        }
        if (res.statusCode != 200) {
          if (res.aaData == false) {
            this.technical_error()
          }
        }
      },
        error => {
          this.technical_error()
        }
      );
  }

  async redirectOnItemDetailPage(item) {
    if (item.is_food_category_time_available == false) {
      this.kioskauthservice.itemNotAvailableAlert();
      return false;
    } else {
      if (item.master_menu_item_is_combo == "YES") {
        if (item.master_food_category_uuid == '12844a2db14f510f90333983f1fa07ad' || item.master_food_category_uuid == '1be5953589919b2b686f94e2b68dccea' || item.master_food_category_uuid == 'a16b3341e0203ea1e3435c5c04b177bd') {
          this.router.navigateByUrl(`product-combo-detail?id=` + item.master_menu_item_uuid);
        }
        else {
          if (item.master_menu_type == "SALAD_COMBO") {
            this.router.navigateByUrl(`bowl-detail?id=` + item.master_menu_item_uuid);
          } else {
            this.router.navigateByUrl(`product-detail?id=` + item.master_menu_item_uuid);
          }
        }
      }
      else {
        // this.router.navigateByUrl(`product-detail?id=` + item.master_menu_item_uuid);
        if (item.master_menu_type == "SALAD_COMBO") {
          this.router.navigateByUrl(`bowl-detail?id=` + item.master_menu_item_uuid);
        } else {
          this.router.navigateByUrl(`product-detail?id=` + item.master_menu_item_uuid);
        }
      }
    }
  }

  getCartItems() {
    let cartDetail = this.store.getUserCart();
    this.cartItems = cartDetail;
    this.cartItemCount = 0;
    this.totalCartAmount = 0;
    Object.entries(cartDetail).forEach(([k, v]) => {
      this.cartItemCount = +v['total_quantity'] + +this.cartItemCount;
      this.totalCartAmount = +v['total'] + +this.totalCartAmount;
      // ------timer------------
      if (this.timer_start == true || this.cartItemCount == 0) {
        this.time_left = cart_time;
        clearInterval(this.time_left_counter);
      }
      if (this.cartItemCount != 0) {
        this.time_left_counter = setInterval(() => {
          this.time_left -= 1;
          if (this.time_left == 0) {
            clearInterval(this.time_left_counter);
            this.store.setUserCart([]);
            this.store.remove("formFields");
            this.store.remove("cart");
            this.alertController.dismiss();
            let Autologin = KIOSK_AUTO_LOGIN
            this.store.hardlogout()
            location.href = Autologin + '/' + this.terminalId;
            return false;
          }
        }, setIntervalTimeOut);
      }
    })
  }

  getTotalQuantity(item) {
    return item.total_quantity;
  }

  isItemCartAvaliable() {
    let restaurantDetails = this.store.getRestaurant();
    let orderitem = this.store.getUserCart();
    let data = {
      ref: "kiosk",
      master_restaurant_uuid: restaurantDetails.master_restaurant_uuid,
      orders: orderitem
    }
    this.kiosk_apiService.isItemCartAvaliable().subscribe(
      (res) => {
        if (res.statusCode == 200) {
          this.checkOut()
        }
        else {
          clearInterval(this.time_left_redirection_counter)
          clearInterval(this.time_left_counter);
          this.soldItemModal(res)
        }
      }
    )
  }

  async soldItemModal(data) {
    let itemImage = data.message.item_image
    if (itemImage == undefined || itemImage == '') {
      itemImage = "assets/image/bell.svg"
    }
    else {
      itemImage = data.message.item_image
    }

    let message = data.message.error
    let html = `<div class="custom-head">
    <span><img src="`+ itemImage + `"></span>
    <h2>Sorry</h2>
    <p>`+ message + `</p>
    </div>`
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class default-modal',
      mode: 'md',
      message: html,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.reset_time();
            if (this.cartItemCount != 0) {
              this.time_left_counter = setInterval(() => {
                this.time_left -= 1;
                if (this.time_left == 0) {
                  clearInterval(this.time_left_counter);
                  this.store.setUserCart([]);
                  this.store.remove("formFields");
                  this.store.remove("cart");
                  let Autologin = KIOSK_AUTO_LOGIN
                  this.store.hardlogout()
                  location.href = Autologin + '/' + this.terminalId;
                  return false;
                }
              }, setIntervalTimeOut);
            }
          }
        }
      ]
    });
    setTimeout(() => {
      this.alertController.dismiss();
      if (this.cartItemCount != 0) {
        this.time_left_counter = setInterval(() => {
          this.time_left -= 1;
          if (this.time_left == 0) {
            clearInterval(this.time_left_counter);
            this.store.setUserCart([]);
            this.store.remove("formFields");
            this.store.remove("cart");
            let Autologin = KIOSK_AUTO_LOGIN
            this.store.hardlogout()
            location.href = Autologin + '/' + this.terminalId;
            return false;
          }
        }, setIntervalTimeOut);
      }
      this.reset_time();
    }, 5000);
    await alert.present();
  }

  checkOut() {
    this.auditlogServices.audit_log("CHECKOUT")
    this.auditlogServices.logEvents("CHECKOUT")
    if (this.app_settings.enable_kiosk_payment) {
      let restaurantDetails = this.store.getRestaurant();
      let master_restaurant_uuid = restaurantDetails.master_restaurant_uuid
      this.obj_restaurantoperational = this.kiosk_apiService.restaurant_close_status(master_restaurant_uuid)
        .subscribe(
          (res) => {
            if (res.statusCode == 200) {
              if (res.aaData == true) {
                clearInterval(this.time_left_counter);
                this.router.navigate([`payment-method`]);
              } else {
                this.kioskauthservice.isRestaurantOpenAlert();
                return false;
              }
            }
            if (res.statusCode != 200) {
              if (res.aaData == false) {
                this.technical_error()
              }
            }
          },
          error => {
            this.technical_error()
          }
        );
    }
    else {
      this.checkout_orderCreate()
    }
  }

  removeItemFromCart(index) {
    let cart = this.store.getUserCart();
    cart.splice(index, 1);
    this.store.setUserCart(cart);
    this.ionViewWillEnter();
    this.auditlogservice.logEvents("CART_ITEM_REMOVED");
    this.auditlogservice.audit_log("CART_ITEM_REMOVED");
  }

  editItem(index, itemUuid) {
    this.router.navigateByUrl('/product-detail?id=' + itemUuid + '&ci=' + index);
  }

  doRefresh(event) {
    this.obj_storeinfo = this.kiosk_apiService.restaurant_Details().subscribe(res => {
      if (res.statusCode == 200) {
        localStorage.removeItem('restaurant');
        this.store.set('restaurant', res.aaData);
        this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
        event.target.complete();
        this.maintain_timer = setInterval(
          () => {
            if (this.shop.master_restaurant_status == 'M_ON') {
              this.is_maintaince_mode_on = true;
            }
            else {
              this.is_maintaince_mode_on = false;
            }
            this.is_maintaince_mode_on = false;
          }
          , telemetryTimeout);
      } else {
        event.target.complete();
      }
      if (res.statusCode != 200) {
        if (res.aaData == false) {
          this.technical_error()
        }
      }
    }, err => {
      event.target.complete();
    });
  }

  getRestaurantDetails() {
    this.obj_restaurantDetails = this.kiosk_apiService.restaurant_Details().subscribe(res => {
      if (res.statusCode == 200) {
        localStorage.removeItem('restaurant');
        this.store.set('restaurant', res.aaData);
        this.store_info = res.aaData
        this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
        this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
        if (this.shop.master_restaurant_status == 'M_ON') {
          this.is_maintaince_mode_on = true;
        }
        else {
          this.is_maintaince_mode_on = false;
        }
        this.homePageScreen()
      }
      if (res.statusCode != 200) {
        if (res.aaData == false) {
          this.technical_error()
        }
      }
    }
      , error => {
        this.technical_error()
      });
  }

  /**
 * Function to get app seting
 */

  getAppSettings() {

    this.obj_AppSetting = this.kiosk_apiService.appsettings().subscribe(response => {
      if (response.status) {
        this.appSettings = response.aaData;
        this.settings = this.appSettings;

        this.app_maintanance_description2 = response.aaData.app_settings.app_maintanance_description ? response.aaData.app_settings.app_maintanance_description : environment.M_ON;
        this.app_ella_maintanance_description2 = response.aaData.app_settings.app_ella_maintanance_description ? response.aaData.app_settings.app_ella_maintanance_description : environment.ELLA_M_ON;
        this.app_store_closed_description2 = response.aaData.app_settings.app_store_closed_description ? response.aaData.app_settings.app_store_closed_description : environment.STORE_CLOSED;
        this.home_banner_image = response.aaData.app_settings.app_store_banner_description_kiosk 
      }
    }, err => {

    });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  show_payment_simulation() {
    this.router.navigateByUrl('/payment-method2');
  }

  ionViewDidLeave() {
    clearInterval(this.telemetry_poller)
    clearInterval(this.terminal_modal)
    clearInterval(this.time_left_redirection_counter);
    this.obj_restaurantDetails.unsubscribe();
    this.obj_restaurantstatus.unsubscribe();
    this.obj_menuItems.unsubscribe();
    this.obj_AppSetting.unsubscribe();
    clearInterval(this.time_left_counter);
    clearInterval( this.display_images)
    clearInterval(this.menuItems_timer)

    clearInterval(this.maintain_timer);
   
  }

  switchOrderType(type) {
    this.orderType = type;
    this.store.set("order_type", type);
  }

  async openSheetModal(item) {
    let itemData = item;
    if (item['combo_items']) {
      itemData = item['combo_items'];
    }
    const modal = await this.modalController.create({
      component: CartItemDetailPage,
      cssClass: 'app-cart-item-detail1',
      mode: 'md',
      componentProps: {
        data: (item?.food_type == 'SALAD_COMBO') ? item : itemData
      },
    });
    setTimeout(() => { modal.dismiss(); }, modalTimeout);
    return await modal.present();
  }

  clock_Timer() {
    setInterval(function () {
      var dt = new Date();
      var ampm = dt.getHours() >= 12 ? 'PM' : 'AM';
      var clock_time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds() + " " + ampm;

      if (clock_time === '7:0:0 AM' || clock_time === '7:30:0 PM' || clock_time === '19:30:0 PM') {
        window.location.reload();
      }
    });
  }

  go_to_maintenance() {
    let terminalid = localStorage.getItem('terminalid')
    terminalid = JSON.parse(terminalid)
    location.href = "http://192.168.10.11:9001/?terminalid=" + terminalid;
  }
  goToCartList() {
    this.router.navigateByUrl('/cart-list');
  }

  reset_time() {
    this.time_left = cart_time;
    clearInterval(this.time_left_redirection_counter);
    this.page_redirection_time_left = page_redirection_time;
    if (this.page_redirection_time_left = page_redirection_time) {
      this.time_left_redirection_counter = setInterval(() => {
        this.page_redirection_time_left -= 1;
        if (this.page_redirection_time_left == 0) {
          clearInterval(this.time_left_redirection_counter);
          this.kiosk_apiService.terminal_dismiss()
          clearInterval(this.telemetry_poller)
          this.store.setUserCart([]);
          this.store.remove("formFields");
          this.store.remove("cart");
          let Autologin = KIOSK_AUTO_LOGIN
          this.store.hardlogout()
          location.href = Autologin + '/' + this.terminalId;
          return false;
        }
      }, setIntervalTimeOut);
    }
  }

  logScrollStart(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
    this.time_left = cart_time;
  }

  logScrolling(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
    this.time_left = cart_time;
  }

  logScrollEnd(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
    this.time_left = cart_time;
  }

  is_item_ella(item) {
    if (item.master_menu_type == 'ELLA' || item.master_food_category_uuid == '0ecd423c3da7fa02266ae19af4b4cdb') {
      return true;
    }
    else {
      return false;
    }
  }

  is_category_coffee(obj) {
    var obj3 = obj.value;
        var obj4 = obj3 ? obj3[0] : {};
        var index = "NO"
    if( obj4.master_food_category_type == "ELLA" || obj4.master_food_category_type == "ella"){
      index = "YES";
    } 
   return index;
  }

  async technical_error(message?) {
    let errorMessage = '<h2>Looks like we hit a snag! You will be redirected back in 5 seconds</h2>';
    if (message) {
      errorMessage = message;
    }
    const alert = this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      header: 'Oops!',//'Please Tap Again',
      message: errorMessage,
      backdropDismiss: false,
      buttons: [
        {
          text: '',
          cssClass: 'cross',
          handler: () => {
            this.alertController.dismiss();
            let Autologin = KIOSK_AUTO_LOGIN
            let terminalid = this.store.get('terminalid')
            this.store.hardlogout()
            location.href = Autologin + '/' + terminalid;
          }
        }
      ]
    });
    setTimeout(() => {
      this.alertController.dismiss();
      let Autologin = KIOSK_AUTO_LOGIN
      let terminalid = this.store.get('terminalid')
      this.store.hardlogout()
      location.href = Autologin + '/' + terminalid;
    }, 5000);
    (await alert).present();
  }

  audit_log() {
    this.auditlogservice.logEvents("HOME");
    this.auditlogservice.audit_log("HOME");
  }


  cartEllaItems() {
    let restaurantDetails = this.store.getRestaurant();
    let master_restaurant_uuid = restaurantDetails.master_restaurant_uuid
    this.kiosk_apiService.restaurant_close_status(master_restaurant_uuid)
      .subscribe(
        (res) => {
          this.ella_max_drinks = res.ella_max_drinks
          // console.log("ella_amax:", this.ella_max_drinks)
        })

    this.haveMaxEllaCoffee = false;
    this.coffeeCount = 0
    this.cartItemDetail = this.store.getUserCart();
    // Object.entries(this.cartItemDetail).forEach(([k, v]) => {
    //   if (v['combo_items'] && Object.keys(v['combo_items']).length > 0) {
    //     Object.entries(v['combo_items']).forEach(([kk, vv]) => {
    //       if (vv['food_type'] == "ELLA") {
    //         this.coffeeCount++
    //       }
    //     })
    //   } else if (v['food_type'] == "ELLA") {
    //     this.coffeeCount++
    //   } else if (v['food_type'] == 'SALAD_COMBO' && v['combo_items'] && Object.keys(v['combo_items']).length > 0) {
    //     this.coffeeCount++
    //   }
    // });

    Object.entries(this.cartItemDetail).forEach(([k, v]) => {
      if (v['type'] == 'ELLA') {
        this.coffeeCount++
      } else if (v['type'] == 'COMBO' && v['combo_items'] && Object.keys(v['combo_items']).length > 0) {
        this.coffeeCount++
      } else if (v['food_type'] == 'SALAD_COMBO' && v['combo_items'] && Object.keys(v['combo_items']).length > 0) {
        this.coffeeCount++
      }
    })



    // console.log("coffe_count:",this.coffeeCount)
    if (this.coffeeCount > this.ella_max_drinks) {
      this.haveMaxEllaCoffee = true;
    }


  }

  payment_printing_setting() {
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
    let data = {
      "master_restaurant_uuid": master_restaurant_uuid
    }
    this.kiosk_apiService.payment_printing_setting(data)
      .subscribe(
        (res) => {
          this.app_settings = res.aaData.module_permissions
        },
        error => {
        }
      );
  }

  loadAllData() {
    const orderId = this.store.get('orderId')
    const items = this.store.isLoggedIn() ? this.store.getUserCart() : this.store.getCart();
    if (items.length == 0 && !orderId) {
      this.router.navigate([`home`]);
      return false;
    }
    let cartDetail = this.store.getUserCart();
    this.totalAmount = 0;
    Object.entries(cartDetail).forEach(([k, v]) => {
      this.totalAmount = v['total'] + +this.totalAmount;
      Object.entries(v).forEach(([key, value]) => {
        this.cartItems_create_order.push(value);
      })
    });
  }

  create_kiosk_order() {
    let cartDetail = this.store.getUserCart();
    this.totalAmount = 0;
    this.cartItems_create_order = [];
    Object.entries(cartDetail).forEach(([k, v]) => {
      this.totalAmount = v['total'] + +this.totalAmount;
      Object.entries(v).forEach(([key, value]) => {
        this.cartItems_create_order.push(value);
      })
    });
    let order_type = "TAKE_AWAY";
    if (this.store.get("order_type") == "Dine In") {
      order_type = "DINE_IN";
    }
    let totalCartAmount = this.totalAmount;
    let cartItemCount = this.store.get("cartCartItemCount")
    let user = this.store.getUser();
    this.attempted = this.totalAmount;
    this.ServiceID = this.generate_rand_num();
    let data = {
      order_info_uuid: this.store.get('orderId'),
      order_info_customer: user.user_information_uuid,
      master_restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
      order_info_total_price: totalCartAmount,
      order_info_total_order_quantity: cartItemCount,
      order_info_total_discount: 0,
      available_redeemed_amount: 0,
      points_redeemed_amount: 0,
      master_coupon_uuid: null,
      master_subscription_uuid: null,
      subscription_mapping_uuid: null,
      used_subscription_quantity: 0,
      order_info_gst: 0, //Number(this.gst.toFixed(2)),
      // order_info_service_tax [OPTIONAL]
      order_info_grand_total: totalCartAmount,
      order_info_delivery_date: "",
      master_restaurant_type: this.store.getRestaurant().master_restaurant_type,
      orders: this.store.getUserCart(),
      order_info_table_number: null,
      order_info_car_plate_number: null,
      order_info_cutlery: 'FALSE',
      order_info_type: order_type,
      order_info_dinningmode: null,
      order_info_dinningtime: null,
      delivery_address: null,
      is_sap_order: "NO",
      country_uuid: this.store.getCountry().master_country_uuid,
      request_from: "BYTE_STATION",
      deducted: this.deducted,
      balance: this.balance,
      serviceID: this.ServiceID,
      cutlerySelected: (this.store.getItem('cutlerySelected') == 'false') ? false : true,
    }
    this.kiosk_apiService.create_kiosk_order(data).subscribe(res => {

      if (res.statusCode == 200) {
        this.updateOrder(res, null, "QUEUED");
        this.store.set("orderId", res.aaData['order_info_uuid']);
        this.store.remove("totalAmount");
        this.store.remove("totalCartAmount");
        this.store.setUserCart([]);
        this.store.remove("selectedItemsInCart");

        if (res.aaData['master_restaurant_status'] == 'M_ON') {
          this.router.navigate([`home`]);
        } else {

        }
      } else {
        if (res.statusCode != 200) {
          if (res.message) {
            this.technical_error(res.message)
          } else {
            this.technical_error();
          }
        }
      }
    }, err => {

      this.auditlogservice.logEvents("OTHER");
      this.auditlogservice.audit_log("OTHER");
    })


  }

  updateOrder(orderData, paymentResponse, isPaymentFailed) {
    this.order_UUID = orderData.aaData.order_info_uuid
    this.isPaymentFailed = isPaymentFailed
    let data = {
      order_info_uuid: this.order_UUID,
      restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
      payment_response: paymentResponse,
      payment_status: isPaymentFailed ? isPaymentFailed : '',
    }

    this.kiosk_apiService.updateOrder(data).subscribe(res => {

      this.store.remove("orderId");
      this.store.remove("totalAmount");
      this.store.remove("totalCartAmount");
      this.store.setCart([]);
      this.store.remove("selectedItemsInCart");
      if (this.isPaymentFailed == 'QUEUED' && this.app_settings.enable_kiosk_print) {
        this.router.navigateByUrl('/settlement-complete?id=' + this.order_UUID + "&c=" + this.proc_card_idi + "&b=");
      }
      else if (!this.app_settings.enable_kiosk_print && this.isPaymentFailed == 'QUEUED') {
        clearInterval(this.time_left_redirection_counter);
        let Autologin = KIOSK_AUTO_LOGIN
        let terminalid = this.store.get('terminalid')
        this.store.hardlogout()
        location.href = Autologin + '/' + terminalid;
      }
      return false;
    }, err => {
      this.auditlogservice.logEvents("OTHER");
      this.auditlogservice.audit_log("OTHER");
    }
    );
  }

  generate_rand_num() {
    var rand: any;
    rand = Math.random();
    rand = rand * 10000000000;
    rand = Math.floor(rand);
    rand = '' + rand;
    rand = rand.substr(0, 8);
    return rand;
  }

  checkout_orderCreate() {
    this.auditlogservice.logEvents("PAYMENT");
    this.auditlogservice.audit_log("PAYMENT");
    this.loadAllData()
    this.create_kiosk_order()
  }

  homePageScreen() {
    if (this.is_maintaince_mode_on == true) {
      // console.log("maintenance")
      this.headerShow = false
    }
    else if (this.is_restaurant_closed_status == 'YES' && this.store_info.master_restaurant_status === 'ACTIVE' ) {
      // console.log("store")
      this.headerShow = false
    }
    else {
      // console.log("else")
      this.headerShow = true
    }
  }

  resetCart() {
    this.cartItemCount = 0;
    this.totalCartAmount = 0;
    this.store.remove(this.store.getUser().user_information_uuid);
    this.auditlogServices.logEvents("CART_ITEM_REMOVED");
    this.auditlogServices.audit_log("CART_ITEM_REMOVED");
    clearInterval(this.time_left_redirection_counter);
    this.kiosk_apiService.terminal_dismiss()
    clearInterval(this.telemetry_poller);
    let Autologin = KIOSK_AUTO_LOGIN
    this.store.hardlogout()
    location.href = Autologin + '/' + this.terminalId;
    
  }





} 