import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { LocalStoreService } from '../services/localstore.service';
import { KioskauthService } from '../services/kioskauth.service';
import { ApiService } from '../services/api.service';
import { UtilityService } from '../services/utility.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateConfigService } from '../services/translate-config.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError } from 'rxjs/internal/observable/throwError';
import { TranslateService } from '@ngx-translate/core';
import {  setIntervalTimeOut, telemetryTimeout, KIOSK_AUTO_LOGIN, apitimeout, card_page_redirection_time, enable_test_buttons, terminalCheck } from '../common/api';
import { timeout } from 'rxjs/operators';
import { KioskApiService } from '../services/kiosk-api.service';
import { CancelPaymentPage } from './cancel-payment/cancel-payment.page';
import * as sha1 from 'js-sha1';
import { environment } from 'src/environments/environment';
import { RetryPaymentPage } from '../retry-payment/retry-payment.page';
import * as moment from 'moment';
// import { PaymentSuccessModalPage } from '../payment-success-modal/payment-success-modal.page';
import { AuditLogService } from '../services/audit-log.service';



@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.page.html',
  styleUrls: ['./payment-method.page.scss'],
})
export class PaymentMethodPage implements OnInit {
  counter;
  cartItems: any = [];
  totalAmount: number;
  currency = "¥";
  showDecimal = true;
  interval: any;
  proc_card_idi;
  pay_sub: any;
  createorderRes;
  wait_for_payment;
  temp_balance: any;
  temp_total: any;
  _suica_alert: any;
  suica_alert_flag = 0;
  ServiceID: any;
  modal_time_count = 0;
  modal_timer;
  time_stop = false;
  show_cancel_button = false;
  mid_cancel = false;
  page_redirection_time_left = card_page_redirection_time;
  time_left_redirection_counter: any;
  attempted: any;
  deducted: any;
  balance: any;
  card_idi: any;
  ic_handling_number: any;
  order_UUID;
  isPaymentFailed;
  app_settings;
  message: any;
  test_pay_sub;
  test_button_status = enable_test_buttons;
  terminalid;
  loading;
  telemetry_poller;
  terminal_modal;

  constructor(
    private store: LocalStoreService,
    private router: Router,
    private kioskauthservice: KioskauthService,
    public alertController: AlertController,
    private api: ApiService,
    private translateConfigService: TranslateConfigService,
    private utilityService: UtilityService,
    private translate: TranslateService,
    public modalController: ModalController,
    private kiosk_apiService : KioskApiService,
    private loadingCtrl:LoadingController,
    public auditlogservice: AuditLogService
  ) {
    this.translateConfigService.getDefaultLanguage();

    this.showDecimal = true;
    if (this.store.getCountry()) {
      let countryData = this.store.getCountry();
      this.currency = countryData['master_country_currency_code'];
      if (countryData['master_country_currency_decimal_places'] == 0) {
        this.showDecimal = false;
      }
    }
  }

  ngOnInit() {
    this.counter = 0;
    this.Page_timer();
  }

  

  ionViewWillEnter() {
    console.log("ionviewwill entwe called")
    this.terminal_modal = this.store.get('terminal_modal')
    setInterval( () =>{
      this.terminal_modal = this.store.get('terminal_modal')
    },500)
    this.kiosk_apiService.telemetry();
    this.telemetry_poller = setInterval(
      ()=>{
       this.kiosk_apiService.telemetry()
      }
    , terminalCheck);
    this.isRestaurantUnderMaintainance();
    this.payment_printing_setting();
    this.terminalid = this.store.get('terminalid');
    if (!this.store.get('sessionId')) {
      this.create_sessionId();
    }
  }
  create_sessionId() {
    const sessionId = Math.random().toString(36).replace(/[^a-z]+/g, "").substr(0, 5) + moment().format('HHMMSS');;
    this.store.set('sessionId', sessionId);
  }



  ionViewDidEnter() {
    this.counter = 0;
  }

  isRestaurantUnderMaintainance() {
    let param = {
      master_restaurant_uuid: localStorage.getItem("master_restaurant_uuid")
    }
    this.kioskauthservice.apiReq('post', 'masterrestaurant/getbyuuid', param)
      .subscribe((res) => {
        if (res.statusCode == 200) {
          if (res.aaData['master_restaurant_status'] == 'M_ON') {
            this.router.navigate([`home`]);
          } else {
            this.loadAllData();
           
          }
        } else {
          this.loadAllData();
        }
        if (res.statusCode != 200) {
          if (res.aaData == false) {
            this.error()
          }
        }
      }, err => {
        this.error()
      });
  }

  timer_for_ui;
  Page_timer() {
   
    clearInterval(this.time_left_redirection_counter);
    this.page_redirection_time_left = card_page_redirection_time;
    this.timer_for_ui = 60;
    if (this.page_redirection_time_left = card_page_redirection_time) {

      this.time_left_redirection_counter = setInterval(() => {
        this.page_redirection_time_left -= 1;
        this.timer_for_ui -= 1;
        if (this.page_redirection_time_left == 10) {
          console.log('here abort payment will be called');
          this.abort_order();
        }
        if (this.page_redirection_time_left == 0) {
          this.kiosk_apiService.terminal_dismiss()
          clearInterval(this.telemetry_poller)
          clearInterval(this.time_left_redirection_counter);
          // this.store.setUserCart([]);
          this.time_out();
          // this.store.remove("formFields");
          // this.store.remove("cart");
          return false;
        }
      }, setIntervalTimeOut);
    }
  }


  loadAllData() {
    const orderId = this.store.get('orderId')
    const items = this.store.isLoggedIn() ? this.store.getUserCart() : this.store.getCart();
    if (items.length == 0 && !orderId) {
      this.router.navigate([`home`]);
      return false;
    }
    else{
      this.create_kiosk_order();
    }
  }
  createOrderResponse;

  create_kiosk_order() {
    let cartDetail= this.store.getUserCart();
    this.totalAmount = 0;
    this.cartItems = [];
    // Object.entries(cartDetail).forEach(([k, v]) => {
    //   this.totalAmount = v[0].total + +this.totalAmount;
    //   Object.entries(v).forEach(([key, value]) => {
    //     this.cartItems.push(value);
    //   })
    // });
    Object.entries(cartDetail).forEach(([k, v]) => {
      this.totalAmount = Number(v['total']) + Number(this.totalAmount);
      Object.entries(v).forEach(([key, value]) => {
        this.cartItems.push(value);
      })
    });
    let envi_key = environment.key_for_sha1
    let concat_key = this.totalAmount+envi_key
    let sha1_key = sha1(concat_key)
    console.log(sha1_key)
    let order_type = "TAKE_AWAY";
    if (this.store.get("order_type") == "Dine In") {
      order_type = "DINE_IN";
    }
    let totalCartAmount = this.totalAmount;
    let cartItemCount = this.store.get("cartCartItemCount")
    let user = this.store.getUser();
    let usercart = this.store.getUserCart()
    let cartyou = usercart[0]
    this.attempted = this.totalAmount;
    this.ServiceID = this.generate_rand_num();
    console.log("this.ServiceID", this.ServiceID)
    let data = {
      session_id: this.store.get('sessionId'),
      order_info_uuid: this.store.get('orderId'),
      hmac: this.genrate_sha1(),
      order_info_customer: user.user_information_uuid,
      master_restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
      order_info_total_price: totalCartAmount,
      order_info_total_order_quantity: 0,
      order_info_total_discount: 0,
      available_redeemed_amount: 0,
      points_redeemed_amount: 0,
      master_coupon_uuid: null,
      master_subscription_uuid: null,
      subscription_mapping_uuid: null,
      used_subscription_quantity: 0,
      order_info_gst: 0,
      order_info_grand_total: totalCartAmount,
      order_info_delivery_date: "",
      master_restaurant_type: this.store.getRestaurant().master_restaurant_type,
      orders: this.store.getUserCart(),
      order_info_table_number: null,
      order_info_car_plate_number: null,
      order_info_cutlery: 'FALSE',
      order_info_type: order_type,
      order_info_dinningmode: null,
      order_info_dinningtime: null,
      delivery_address: null,
      is_sap_order: "NO",
      country_uuid: this.store.getCountry().master_country_uuid,
      request_from: "BYTE_STATION",
      deducted: this.deducted,
      balance: this.balance,
      serviceID: this.ServiceID,
      cutlerySelected: (this.store.getItem('cutlerySelected') == 'false') ? false : true,

    }

    this.auditlogservice.logEvents("PAYMENT",data,"CREATE_ORDER_PAYLOAD");
    this.auditlogservice.audit_log("PAYMENT",data,"CREATE_ORDER_PAYLOAD");

      this.kiosk_apiService.create_kiosk_order(data).subscribe((res) => {     


    this.auditlogservice.logEvents("PAYMENT",res,"CREATE_ORDER_RES");
    this.auditlogservice.audit_log("PAYMENT",res,"CREATE_ORDER_RES");

        if (res.statusCode == 200) {
          this.createOrderResponse = res;
          this.createPayment(res);
          this.store.set("orderId", res.aaData['order_info_uuid']);
          if (res.aaData['master_restaurant_status'] == 'M_ON') {
            this.router.navigate([`home`]);
          } else {
          }
        } else {
          if (res.statusCode != 200) {
            if (res.message) {
              this.technical_error(res.message)
            } else {
              this.technical_error();
            }
          }
        }
      }, err => {
       
    this.auditlogservice.logEvents("PAYMENT",err,"CREATE_ORDER_ERR");
    this.auditlogservice.audit_log("PAYMENT",err,"CREATE_ORDER_ERR");

      })
    }
    genrate_sha1() {
      let envi_key = environment.key_for_sha1
      let concat_key = this.totalAmount + envi_key
      let sha1_key = sha1(concat_key)
      return sha1_key;
    }
  
  
    
    createPayment(orderData) {
      this.createorderRes = orderData
      if (this.app_settings.enable_kiosk_payment) {
        let TransactionID = orderData.aaData.reference_number
        let date = new Date()
        console.log(date)
        const paymentQueryParams = {
          serviceID: this.ServiceID,
          totalAmount: this.totalAmount,
          terminalId: this.terminalid,
          transactionID: orderData.aaData.order_info_uuid
        }
        this.auditlogservice.logEvents("PAYMENT", paymentQueryParams, "PAYMENT_PAYLOAD");
        this.auditlogservice.audit_log("PAYMENT", paymentQueryParams, "PAYMENT_PAYLOAD");
        this.pay_sub = this.api.wait_for_payment(this.ServiceID, this.totalAmount, this.terminalid, TransactionID).pipe(timeout(apitimeout)).subscribe(
          r => {
            clearInterval(this.interval);
            let result1 = this.stripslashes(r)
            console.log(result1.length, "replace1", result1)
            let result2 = result1.replace(/“/g, '"');
            console.log(result2.length, "replace2", result2)
            this.wait_for_payment = result2.replace(/”/g, '"');
            let lengths = this.wait_for_payment.length
            if (this.wait_for_payment.charAt(0) == '"' && this.wait_for_payment.charAt(lengths - 1) == '"') {
              this.wait_for_payment = this.wait_for_payment.slice(1, -1)
            }
            console.log("Response 1 ======>", r);
            if (this.kiosk_apiService.isJsonString(this.wait_for_payment)) {
              console.log("Response 1.1 ======>", this.wait_for_payment);
              this.wait_for_payment = JSON.parse(this.wait_for_payment)
            } else {
              console.log("Response 1.2 ======>", r);
              this.wait_for_payment = r
            }
            console.log("Response 1.3 ======>", this.wait_for_payment);
            this.payment_response_process(this.wait_for_payment) // new logic function
          },
          err => {
            console.log("PAYMNET API ERROR RESPONSE========", err)
            if (err.error) {
              let result1 = this.stripslashes(err.error)
              let result2 = result1.replace(/“/g, '"');
              this.wait_for_payment = result2.replace(/”/g, '"');
              let lengths = this.wait_for_payment.length
              if (this.wait_for_payment.charAt(0) == '"' && this.wait_for_payment.charAt(lengths - 1) == '"') {
                this.wait_for_payment = this.wait_for_payment.slice(1, -1)
              }
              if (this.kiosk_apiService.isJsonString(this.wait_for_payment)) {
                this.wait_for_payment = JSON.parse(this.wait_for_payment)
              } else {
                this.wait_for_payment = err.error
              }
              if (this.wait_for_payment.aaData && this.wait_for_payment.aaData.message) {
                this.store.remove("totalAmount");
                this.store.remove("totalCartAmount");
                this.payment_response_modal(this.wait_for_payment.aaData.message)
              } else {
                // this.error();
              }
            }
            else {
              // this.error();
            }
            this.auditlogservice.logEvents("PAYMENT", err, "PAYMENT_TIMEOUT");
            this.auditlogservice.audit_log("PAYMENT", err, "PAYMENT_TIMEOUT");
          }
        );
      }
      else {
        this.updateOrder(orderData, null, "QUEUED");
      }
    }
  
    payment_response_process(payment_info) {
      if (payment_info.statusCode == 200) {
        this.store.remove("totalAmount");
        this.store.remove("totalCartAmount");
        this.auditlogservice.logEvents("PAYMENT", payment_info, "SUCCESS");
        this.auditlogservice.audit_log("PAYMENT", payment_info, "SUCCESS");
        this.updateOrder(this.createorderRes, payment_info, "QUEUED");
      }
      else {
        this.store.remove("totalAmount");
        this.store.remove("totalCartAmount");
        this.auditlogservice.logEvents("PAYMENT", payment_info, "PAYMENT_ERROR");
        this.auditlogservice.audit_log("PAYMENT", payment_info, "PAYMENT_ERROR");
        this.payment_response_modal(payment_info.message)
      }
    }
  
    updateOrder(orderData, paymentResponse, isPaymentFailed) {
      this.deducted = this.totalAmount;
      this.order_UUID = orderData.aaData.order_info_uuid
      this.isPaymentFailed = isPaymentFailed;
      this.balance = paymentResponse.aaData ? paymentResponse.aaData.posOriginalAmountValue : "";
      let mid = paymentResponse.aaData ? paymentResponse.aaData.mid : "";
      let data = {
        order_info_uuid: this.order_UUID,
        restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
        payment_response: paymentResponse,
        payment_status: isPaymentFailed ? isPaymentFailed : '',
        suica_payment_details: { "deducted": this.deducted, "balance": this.balance, "paymentid": mid, "RESPONSE": paymentResponse }
      }
  
      this.auditlogservice.logEvents("PAYMENT", data, "UPDATE_ORDER_PAYLOAD");
      this.auditlogservice.audit_log("PAYMENT", data, "UPDATE_ORDER_PAYLOAD");
  
      this.kiosk_apiService.updateOrder(data).subscribe(res => {
        this.auditlogservice.logEvents("PAYMENT", res, "UPDATE_ORDER_RESPONSE");
        this.auditlogservice.audit_log("PAYMENT", res, "UPDATE_ORDER_RESPONSE");
        this.store.remove("orderId");
        this.store.remove("totalAmount");
        this.store.remove("totalCartAmount");
        if (this.isPaymentFailed == 'QUEUED' && this.app_settings.enable_kiosk_print) {
          const redirectUrl = "/settlement-complete?id=" + this.order_UUID + "&c=" + this.proc_card_idi + "&b=";
          this.router.navigateByUrl('/settlement-complete?id=' + this.order_UUID + "&c=" + this.proc_card_idi + "&b=");
        }
        else if (!this.app_settings.enable_kiosk_print && this.isPaymentFailed == 'QUEUED') {
          clearInterval(this.time_left_redirection_counter);
          let Autologin = KIOSK_AUTO_LOGIN
          this.store.hardlogout()
          location.href = Autologin + '/' + this.terminalid;
        }
        return false;
      }, err => {
        this.auditlogservice.logEvents("PAYMENT", err, "UPDATE_ERR");
        this.auditlogservice.audit_log("PAYMENT", err, "UPDATE_ERR");
        const payment_cancel_timeout = setTimeout(() => {
          clearInterval(this.time_left_redirection_counter);
          let Autologin = KIOSK_AUTO_LOGIN
          this.store.hardlogout()
          location.href = Autologin + '/' + this.terminalid;
        }, 100)
      }
      );
  
    }
  
  
    cancelOrder() {
      clearInterval(this.time_left_redirection_counter);
      this.mid_cancel = true;
      let cancel_payment = {
        serviceId: this.ServiceID,
        terminalid: this.terminalid
      }
      this.auditlogservice.logEvents("PAYMENT", cancel_payment, "CANCEL_PAYMENT_PAYLOAD");
      this.auditlogservice.audit_log("PAYMENT", cancel_payment, "CANCEL_PAYMENT_PAYLOAD");
      this.api.wait_for_abort_payment(this.ServiceID, this.terminalid)
        .subscribe(res => {
          this.auditlogservice.logEvents("PAYMENT", res, "ABORT");
          this.auditlogservice.audit_log("PAYMENT", res, "ABORT");
        });
      this.store.remove("orderId");
      this.store.remove('sessionId');
      this.ServiceID = this.generate_rand_num();
      this.router.navigate([`cart-list`]);
    }
  
  
    abort_order() {
      let terminalid = this.store.get('terminalid')
      let data = {
        serviceId: this.ServiceID,
        terminalid: terminalid
      }
      this.auditlogservice.logEvents("PAYMENT", data, "ABORT_ORDER_PAYLOAD");
      this.auditlogservice.audit_log("PAYMENT", data, "ABORT_ORDER_PAYLOAD");
  
      this.api.wait_for_abort_payment(this.ServiceID, terminalid)
        .subscribe(res => {
          this.auditlogservice.logEvents("PAYMENT", res, "ABORT");
          this.auditlogservice.audit_log("PAYMENT", res, "ABORT");
        });
  
    }
  

    stripslashes(str) {
      return (str + '')
        .replace(/\\(.?)/g, function (s, n1) {
          switch (n1) {
            case '\\':
              return '\\'
            case '0':
              return '\u0000'
            case '':
              return ''
            default:
              return n1
          }
        })
    }
  

    generate_rand_num() {
      var rand: any;
      rand = Math.random();
      rand = rand * 10000000000;
      rand = Math.floor(rand);
      rand = '' + rand;
      rand = rand.substr(0, 8);
      return rand;
    }
  
    payment_printing_setting() {
      let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
      let data = {
        "master_restaurant_uuid": master_restaurant_uuid
      }
      this.kiosk_apiService.payment_printing_setting(data)
        .subscribe(
          (res) => {
            this.app_settings = res.aaData.module_permissions
          },
          error => {
          }
        );
    }
  
    go_to_maintenance() {
      let terminalid = localStorage.getItem('terminalid')
      terminalid = JSON.parse(terminalid)
      location.href = "http://192.168.10.11:9001/?terminalid=" + terminalid;
    }

    TestCreatePayment(buttonstatus) {
      if (this.pay_sub)
      this.pay_sub.unsubscribe();
      let TransactionID = this.createorderRes.aaData.reference_number
      let order_info_uuid = this.store.get('orderId')
      if (this.app_settings.enable_kiosk_payment) {
        const paymentQueryParams = {
          serviceID: this.ServiceID,
          totalAmount: this.totalAmount,
          terminalId: this.terminalid,
        }
        this.auditlogservice.logEvents("PAYMENT", paymentQueryParams, "PAYMENT_PAYLOAD");
        this.auditlogservice.audit_log("PAYMENT", paymentQueryParams, "PAYMENT_PAYLOAD");
  
  
  
        this.test_pay_sub = this.api.wait_for_Test_payment(this.ServiceID, this.totalAmount, this.terminalid, buttonstatus, order_info_uuid, TransactionID).subscribe(
          r => {
            let result1 = this.stripslashes(r)
            let result2 = result1.replace(/“/g, '"');
            this.wait_for_payment = result2.replace(/”/g, '"');
            let lengths = this.wait_for_payment.length
            if (this.wait_for_payment.charAt(0) == '"' && this.wait_for_payment.charAt(lengths - 1) == '"') {
              this.wait_for_payment = this.wait_for_payment.slice(1, -1)
            }
            if (this.kiosk_apiService.isJsonString(this.wait_for_payment)) {
              this.wait_for_payment = JSON.parse(this.wait_for_payment)
            } else {
              this.wait_for_payment = r
            }
            this.payment_response_process(this.wait_for_payment) // new logic function
          },
          err => {
            console.log("PAYMNET API ERROR RESPONSE========", err)
            if (err.error) {
              let result1 = this.stripslashes(err.error)
              let result2 = result1.replace(/“/g, '"');
              this.wait_for_payment = result2.replace(/”/g, '"');
              let lengths = this.wait_for_payment.length
              if (this.wait_for_payment.charAt(0) == '"' && this.wait_for_payment.charAt(lengths - 1) == '"') {
                this.wait_for_payment = this.wait_for_payment.slice(1, -1)
              }
              if (this.kiosk_apiService.isJsonString(this.wait_for_payment)) {
                this.wait_for_payment = JSON.parse(this.wait_for_payment)
              } else {
                this.wait_for_payment = err.error
              }
              if (this.wait_for_payment.aaData && this.wait_for_payment.aaData.message) {
                this.store.remove("totalAmount");
                this.store.remove("totalCartAmount");
                this.payment_response_modal(this.wait_for_payment.aaData.message)
              } else {
                this.error();
              }
            }
            else {
              this.error();
            }
            this.auditlogservice.logEvents("PAYMENT", err, "PAYMENT_TIMEOUT");
            this.auditlogservice.audit_log("PAYMENT", err, "PAYMENT_TIMEOUT");
          }
  
        );
  
      }
      else {
        this.updateOrder(this.createorderRes, null, "QUEUED");
      }
    }
  


  
    ionViewDidLeave() {
      clearInterval(this.terminal_modal)
      clearInterval(this.telemetry_poller);
      clearInterval(this.time_left_redirection_counter);
      this.store.remove("orderId");
      if (this.pay_sub)
      this.pay_sub.unsubscribe();
    }
  
  //Abort payment
  async cancel_payment() {
    clearInterval(this.time_left_redirection_counter);
    const alert = await this.modalController.create({
      component: CancelPaymentPage,
      cssClass: 'cancel-payment'
    }
    );
    return await alert.present();
  }

  async processing(){
   
    this.loading = await this.loadingCtrl.create({
      message: '<div  class="conatiner"><ion-img class="ella-logo" src="../../assets/image/Ella_loading.gif" alt="loading..."></ion-img><p class ="text">Processing Your Payment</p><ion-img class="ella-loader" src="../../assets/image/loading.gif" alt="_loading..."></ion-img></div>',
      cssClass: 'loader-class',
      spinner: null
    });
    await this.loading.present();
    }

    payment_response_modal(message) {
      const repaymentCount = this.store.get('retryPaymentCount');
      if (repaymentCount >= 3) {
        this.autoLogin();
      } else {
        this.payment_response_present_modal(message);
      }
    }

    async payment_response_present_modal(message) {
      clearInterval(this.time_left_redirection_counter);
      this.message = message;
      const modal = await this.modalController.create({
        component: RetryPaymentPage,
        cssClass: 'payment-error-modal',
        backdropDismiss: false,
        componentProps: {
          'responseMessage': this.message,
        }
      });
      modal.onDidDismiss().then((data) => {
        console.log("payment-error-modal", data);
        if (data.data.dismiss_type == 'retry' && data.data.resetPaymentCount <= 3) {
          this.ServiceID = this.generate_rand_num();
          this.retryOrderPayment();
        } else if (data.data.dismiss_type == 'cancelOrder') {
          this.cencelOrder();
        } else {
          this.autoLogin();
        }
      });
      return await modal.present();
    }

    time_out() {
      this.payment_response_modal('Time out')
    }
    error(message?) {
      this.payment_response_modal('Looks like we hit a snag! You will be redirected back in 5 seconds')
    }
    technical_error(message?) {
      const passMessage = message ? message : 'Technical Error';
      this.payment_response_modal(passMessage)
    }

    retryOrderPayment() {
      this.terminal_modal = this.store.get('terminal_modal')
      setInterval( () =>{
        this.terminal_modal = this.store.get('terminal_modal')
      },500)
      this.kiosk_apiService.telemetry();
      this.telemetry_poller = setInterval(
        ()=>{
        this.kiosk_apiService.telemetry()
        }
      , terminalCheck);
      this.Page_timer();
      this.createPayment(this.createOrderResponse);
    }
    cencelOrder() {

    }
    autoLogin() {
      clearInterval(this.time_left_redirection_counter);
      let Autologin = KIOSK_AUTO_LOGIN
      this.store.hardlogout()
      location.href = Autologin + '/' + this.terminalid;
    }
    
    // async paymentSuccessModal(redirectUrl) {
    //   clearInterval(this.time_left_redirection_counter);
    //   const modal = await this.modalController.create({
    //     component: PaymentSuccessModalPage,
    //     cssClass: 'payment-sucess-modal',
    //     backdropDismiss: false,
    //   });
    //   modal.onDidDismiss().then((data) => {
    //     console.log("payment-success-modal", 'redirect to next page');
    //     clearInterval(this.terminal_modal)
    //     clearInterval(this.telemetry_poller);
    //     this.router.navigateByUrl(redirectUrl)
    //   });
    //   return await modal.present();
    // }
  
  }



