<ion-button (click)="go_to_maintenance()" class="maintaince-mode-btn"> </ion-button>
<ion-content>  
  <div class="paymentSection">
  <h2>Tap your card on the terminal to pay within <span *ngIf="timer_for_ui > 0">{{timer_for_ui}}</span><span *ngIf="timer_for_ui <= 0">0</span> seconds</h2>
  <img src="../../assets/image/Payment_GIF.gif">
  <div>
    <img class="cardsListImg" src="../../assets/image/cardsList.png">
  </div>
    <div *ngIf="page_redirection_time_left <= 10" class="paymentLoader">
      <div class="nb-spinner"></div>
    </div>
<div *ngIf = "test_button_status">
  <ion-button (click)="TestCreatePayment('SUCCESS')"> TEST ORDER for  $ {{totalAmount}} </ion-button>
 <ion-button (click)="TestCreatePayment('FAILURE')" color="danger">Payment Failed</ion-button>
  <ion-button (click)="error()" color="danger">no response</ion-button>
</div>
</div>
</ion-content>

<ion-footer>
<ion-toolbar>
    <ion-title mode="md">
      <ion-button (click)="cancelOrder()" >Cancel Payment</ion-button>
    </ion-title>
  </ion-toolbar>
</ion-footer>
