<ion-button (click)="go_to_maintenance()" class="maintaince-mode-btn">  </ion-button>

<ion-content>

  <div class="complete">
    <img src="../../assets/image/paymentDoneCheck.png" class="success">
    <h2>Payment Successful! <br>Thank you for your order!</h2>
    <h5>Please keep your QR code receipt <br>and scan it once your drink is <br>ready to be collected</h5>
    
    <div class="order">
      <span>Your Order No. is</span>
      <h4>No {{render_order_number}}</h4>
      <img class="scanReciptImage" src="../../assets/image/Scan-QR-receipt-v2.gif">    
      <ion-button (click)=" redirectToHome()">RETURN TO HOME</ion-button>
    </div>  
    </div>
</ion-content>
