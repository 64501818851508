import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { KioskauthService } from '../services/kioskauth.service';
import { LocalStoreService } from '../services/localstore.service';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { KioskApiService } from '../services/kiosk-api.service';
import { AuditLogService } from '../services/audit-log.service';

@Component({
  selector: 'app-kioskautologin',
  templateUrl: './kioskautologin.page.html',
  styleUrls: ['./kioskautologin.page.scss'],
})
export class KioskautologinPage implements OnInit {
  id: any;
  uuid: any;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private kioskauthservice: KioskauthService,
    private route: ActivatedRoute,
    private store: LocalStoreService,
    private auditlogservice : AuditLogService
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
    // console.log("  ID: " + this.id);
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    // console.log("  UUID: " + this.uuid);
    let tid = this.route.snapshot.paramMap.get('tid');
    // if(tid){
      this.store.set('terminalid', tid);
    // }
   if(tid !== null && tid !== "null"){
    let payload = { "user_information_uuid": this.id, "user_hmac": this.uuid, "restaurant_type": "bytestation" };
    this.kioskauthservice
      .apiReq('post', 'auth/elologin', payload)
      .subscribe((response) => {
        if (response.status === false) {
          this.kioskauthservice.onError(response.message);
        } else {
          let data = response.aaData;
          localStorage.setItem("master_restaurant_uuid", data.master_restaurant_uuid);
          localStorage.setItem("user_information_uuid", data.user_information_uuid);
          let params = {
            master_restaurant_uuid: data.master_restaurant_uuid
          };
          this.kioskauthservice
            .apiReq('post', 'masterrestaurant/getbyuuid', params)
            .subscribe((response) => {
              if (response.statusCode == 200) {
                this.store.set("user", data);
                this.store.set("restaurant", response.aaData);
                this.store.set("selectedCountry", data.country_detail);
                this.create_sessionId();
                let language: any = {};
                language['language_uuid'] = data.language.master_language_uuid;
                language['language_code'] = data.language.master_language_code;
                language['language_name'] = data.language.master_language_name;
                this.store.set("language", language);
                this.auditlogservice.logEvents("AUTO_LOGIN");
                this.auditlogservice.audit_log("AUTO_LOGIN");
                this.router.navigate(['/scan']);
              }
            })
        }
      });
   }
   else{
     console.log("TerminalId missing")
   }
  }

  public process_autologin(API_URL, payload) {

    this.httpClient.post(API_URL, payload, this.httpOptions)
      .subscribe(data => {
        // console.log("DATA: ", data);

      }, error => {
        // console.log("ERROR: ",error);
      });
  }

  ngOnInit() {

  }
  create_sessionId() {
    const sessionId = Math.random().toString(36).replace(/[^a-z]+/g, "").substr(0, 5) + moment().format('HHMMSS');;
    this.store.set('sessionId', sessionId);
  }

}
