<ion-content>
  <div class="paymentRetrySectionOuter">
    <div class="paymentRetrySection">
      <img src="../../assets/image/errorIcon.png">
      <h1>An error occurred during the transaction</h1>
      <div class="errorMessageSection">
        {{responseMessage}}
      </div>
      <div class="actionButtons">
        <ion-button class="borderButton retryButton" (click)="retryPayment()">Retry</ion-button>
        <ion-button class="borderButton cancelButton" (click)="cancelOrder()">Cancel order</ion-button>
      </div>
      <div class="resetText">
        order will reset in {{page_redirection_time_left}}...
      </div>
    </div>
  </div>
</ion-content>
