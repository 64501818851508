import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModalController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { PageLoaderService } from './page-loader.service';
import { API_URL,LOCAL_PAYMENT_API_URL, LOCAL_PAYMENT_PORT } from '../common/api';
import { LocalStoreService } from '../services/localstore.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { getAnalytics, logEvent } from 'firebase/analytics';
 

@Injectable({
  providedIn: 'root'
})
export class AuditLogService {

  Orderstatus = new Subject();
  Orderuuid = new Subject();
  confirmationResult: any;

  constructor(
    private httpClient: HttpClient,
private toastController: ToastController,
private pageLoaderService: PageLoaderService,
private router: Router,
private store: LocalStoreService,
private modalController: ModalController,
private translate: TranslateService,
public alertController: AlertController,
  ) { }

  apiReq(method: string, endPoint: string, payload?: any): Observable<any> {
    let user = this.store.get('user')
    let options: any = {
    headers: new HttpHeaders({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${user.token}`,
    // 'token': '75FC9C20269896C09FB07E47F09AAF864AFC961F3C6FDEFB200F43E3876FA884',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Accept-Language': this.store.getLanguageUuid(),
    'Accept-Language-Code': this.store.getLanguageCode()
    })
    }
    switch (method) {
    case 'get':
    return this.httpClient.get(API_URL + endPoint, options );
    case 'post':
    return this.httpClient.post(API_URL + endPoint, payload,options);
    case 'put':
    return this.httpClient.put(API_URL + endPoint, payload);
    case 'patch':
    return this.httpClient.patch(API_URL + endPoint, payload);
    case 'delete':
    return this.httpClient.delete(API_URL + endPoint, payload);
    default:
    return null;
    }
    }

  logEvents(screen,payment?, payment_status?) {
    // let payment_response = Object.assign({},payment);
    let restaurant = this.store.get("restaurant")
    let terminalid = this.store.get("terminalid")
    let session_id = this.store.get("sessionId")
    const analytics = getAnalytics();
    logEvent(analytics, 'logEvents', {
      "terminal_id":terminalid ,
      "session_id": session_id,
      "restaurant_uuid": restaurant.master_restaurant_uuid,
      "restaurant_status":restaurant.master_restaurant_status,
      "request_from": screen,
      "cart": this.store.get(this.store.getUserId()) ? this.store.get(this.store.getUserId()) : {},
      "payment": payment,
      "payment_status":payment_status
    })
  }
  
  
//Log API Crash/Exception/Error
audit_log(screen,payment?,payment_status?){
  // let payment_response =Object.assign({},payment );
  let cart = this.store.get(this.store.getUserId()) ? this.store.get(this.store.getUserId()) : {}
let restaurant = this.store.get("restaurant")
const payload = {
  "terminal_id": this.store.get("terminalid") ,
  "session_id": this.store.get("sessionId"),
  "restaurant_uuid": restaurant.master_restaurant_uuid,
  "restaurant_status":restaurant.master_restaurant_status,
  "request_from": screen,
  "cart": cart,
  "payment": payment,
  "payment_status":payment_status
}

this.apiReq('post', 'ssouserinformation/kioskauditlog', payload)
.subscribe(
(res) => {
}, error =>{
},
);
 
}
}
